<template>
    <div>
        <el-dialog title="置顶" :visible.sync="show" width="30%">
            <el-form :model="form">
                <el-tabs type="card" v-model="form.topType">
                    <div style="height: 20px;"></div>
                    <el-tab-pane label="大厅置顶" name="1">
                        <el-form-item :label-width="'auto'" label="置顶时间（小时）">
                            <el-input-number v-model="form.hours" autocomplete="on"></el-input-number>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="板块置顶" name="2">
                        <el-form-item :label-width="'auto'" label="行业置顶板块">
                            <div style="position: relative; top:-6px;" v-if="row.tag.business">
                                <el-badge
                                    value="置顶中"
                                    class="item"
                                    v-for="(item, index) in row.tag.business"
                                    :key="index"
                                    :hidden="!item.top"

                                >
                                    <el-tag @click="toPush(item)" :effect="getEffect(item)" size="small">
                                        {{ item.name }}
                                    </el-tag>
                                </el-badge>
                            </div>
                        </el-form-item>
                      <el-form-item :label-width="'auto'" label="领域置顶板块" v-if="row.tag.field">
                        <div style="position: relative; top:-6px;">
                          <el-badge
                              value="置顶中"
                              class="item"
                              v-for="(item, index) in row.tag.field"
                              :key="index"
                              :hidden="!item.top"
                          >
                            <el-tag @click="toPush(item)" :effect="getEffect(item)" size="small">
                              {{ item.name }}
                            </el-tag>
                          </el-badge>
                        </div>
                      </el-form-item>
                        <el-form-item :label-width="'auto'" label="置顶时间（小时）">
                            <el-input-number v-model="form.hours" autocomplete="on"></el-input-number>
                        </el-form-item>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="show = false">取 消</el-button>
                <el-button type="primary" @click="cooperationTo">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    inject: ["getlist"],
    data() {
        return {
            form: {
                hours: 24,
                category: [],
                topType: "1",
            },
            show: false,
            row: {},
        };
    },
    methods: {
        async cooperationTo() {
            if (this.form.topType == "2") {
                if (this.form.category.length < 1) {
                    this.$message.warning("请选择置顶板块");
                    return;
                }
            }
            let { data: res } = await this.$http.post("/admin/Cooperation/top", {
                id: this.row.id,
                ...this.form,
              businessField:JSON.stringify(this.form.category),
              category:''
                // category: this.form.category.toString(),
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.show = false;
                this.getlist();
            }
        },
        toPush(item) {
            let { top, show,value,type ,name} = item;
            if (top) return this.$message.warning("合作正在该版块置顶中");
            if (this.form.category.find(v => v.value == value)) {
                this.form.category = this.form.category.filter((v) => v != engName);
            } else {
                this.form.category.push({
                  name,value,
                  type
                });
            }
        },
        getEffect(row) {
            if (this.form.category.find(item => row.value == item.value)) {
                return "dark";
            }
            return "plain";
        },
        init(val) {
            this.row = val;
            this.show = true;
            this.form.category = [];
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.el-badge__content.is-fixed {
    right: 30px;
}
.item {
    margin: 5px;
    cursor: pointer;
}
</style>
